<template>
  <div>
    <ul v-if="orderItems.length > 0" class="border border-hr rounded py-8 px-12">
      <div v-for="(order, idx) in orderItems" :key="idx">
        <li class="flex items-center justify-between">
          <div class="flex flex-1 items-center w-0">
            <span class="h-32 px-5 min-w-32 flex items-center justify-center rounded-full bg-green-200">
              <slot name="quantity" :item="order" :index="idx" />
            </span>

            <p class="ml-8 whitespace-no-wrap truncate"><slot name="title" :item="order" :index="idx" /></p>
            <p class="ml-8 text-grey-400 truncate"><slot name="info" :item="order" :index="idx" /></p>
          </div>

          <div class="flex flex-shrink-0 items-center ml-16">
            <slot :item="order" :index="idx" />
          </div>
        </li>
        <hr v-if="idx !== orderItems.length - 1" class="my-8 -mx-12 border-none h-1 bg-hr">
      </div>
    </ul>

    <div class="flex">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'ElOrderItem',

  props: {
    orderItems: { type: Array, default: () => [] }
  }
}
</script>

<style lang="scss" scoped>
</style>
